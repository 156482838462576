// @ts-nocheck

import sendPost from "./poll";
import { toast } from "svelte-sonner";
import { goto } from "$app/navigation";


async function checkWaitlist(code) {
        try {
            const res = await fetch(`https://app.tbcla.com/check_waitlist/${code}`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            }).then((res) => res.json());
            if (res.error) {
                toast.error(res.error);
            }

            if (res.message) {
                toast.success(res.message);
                goto('/users');
            }
         
        } catch (error) {
            toast.error(error);
        }
}

function handlepricecard(product, email) {
    url = fetch('https://app.tbcla.com/create-checkout-session', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Accept': 'application/json',

      },
        credentials: 'include',
        body: JSON.stringify({
          product: product,
          email: email,
        }),
    })
      .then((res) => res.json())
      .then((data) => {
        window.location.href = data.url;
      });
  }

  function handleUserPortal(id) {
    url = fetch('https://app.tbcla.com/create-portal-session', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Accept': 'application/json',

      },
        credentials: 'include',
        body: JSON.stringify({
          id : id,
        }),
    })
      .then((res) => res.json())
      .then((data) => {
        window.location.href = data.url;
      });
  }



function updateUser(editID, editStatus, editPassword) {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await fetch(`https://app.tbcla.com/update_user/${editID}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({editStatus, editPassword }),
            });
            if (!res.ok) {
                throw Error('Status code is ' + res.status);
            } else {
                toast.success('Updated!');
            }

            sendPost();
            resolve();
        } catch (error) {
            reject(error);
        }
    });
}

function deleteUser(id) {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await fetch(`https://app.tbcla.com/delete_user/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include',
                mode: 'cors',
            })
            if (!res.ok) {
                throw Error('Status code is ' + res.status);
            }
            else {
                toast.success('Deleted!');
            }
            
            sendPost();
            resolve();
        } catch (error) {
            reject(error);
        }
    });
}

function toastDelUser(id) {
    if (confirm('Are you sure you want to delete this User?')) {


        toast.promise(deleteUser(id), {
            loading: 'Deleting...',
            success: 'Deleted!',
            error: 'Error deleting order',
        });

    }
}







/**
 * @param {string} id
 * @returns {Promise<void>}
 */
function deleteOrder(id) {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await fetch(`https://app.tbcla.com/delete_order/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include',
                mode: 'cors',
            })
            if (!res.ok) {
                throw Error('Status code is ' + res.status);
            }
            else {
                toast.success('Deleted!');
            }
            
            sendPost();
            resolve();
        } catch (error) {
            reject(error);
        }
    });
}

function toastDel(id) {
    // alert('Are you sure you want to delete this order?');
    if (confirm('Are you sure you want to delete this order?')) {


        toast.promise(deleteOrder(id), {
            loading: 'Deleting...',
            success: 'Deleted!',
            error: 'Error deleting order',
        });

    }
}

export { toastDel, updateUser, checkWaitlist, handlepricecard, toastDelUser, handleUserPortal };